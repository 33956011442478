/* styles/footer.css */
.footer {
    width: 100%;
    background-color: #EAE9E4;
    color: #040D47;
    text-align: center;
    padding: 10px 0;
    position: relative;
    margin-top: 50px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 0.8em;
  }
  
  .footer-link {
    color: #040D47;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #F4AF1B;
  }
  

  @media (max-width: 500px) {
    .footer{
      display: none;
    }
  }