.global-contact-container {
    display: flex;
    width: 80%;
    padding-top: 200px;
}

.title-illustration-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: slideInFromLeft 1s ease-out;
}

.title-illustration-container h1 {
    font-size: 45px;
    font-weight: 100;
    color: #040D47;
}

.illustration {
    width: 200px;
    opacity: 0.2;
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    color: #040D47;
    width: 50%;
    animation: slideInFromRight 1s ease-out;
}

.contact-bloc {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.contact-link {
    color: #040D47;
    text-decoration: none;
}

.contact-image-box {
    background-color: lightgrey;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 50px;
    width: 50px;
}

.contact-image-box img{
    width: 100%;
    height: 100%;
}

.contact-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    margin-bottom: 20px;
    padding-left: 50px;
}

.contact-info p{
    font-weight: 800;
} 


@media (max-width: 768px) {
    .global-contact-container{
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .illustration {
        width: 100px;
        opacity: 0.2;
    }

    .contact-image-box {
        background-color: lightgrey;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        height: 20px;
        width: 20px;
    }

    .global-contact-container {
        padding-top: 100px;
    }

}


@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0); 
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(150%); 
        opacity: 0;
    }
    100% {
        transform: translateX(0); 
        opacity: 1;
    }
}