/* Importation des polices et styles globaux */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

header {
  position: relative;
}

.top-block {
  background-color: #EAE9E4;
  width: 100%;
  height: 50px;
  transition: opacity 0.3s ease-out, height 0.3s ease-out;
}

.top-block.hidden {
  opacity: 0;
  height: 0;
}

.top-block.hide {
  display: none;
}

.nav-container {
  position: absolute;
  top: 50px;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.nav-container.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.nav-container.fixed.small {
  height: 120px;
}

.nav-container.fixed.small::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  border-bottom: 0.5px solid lightgray;
}

.nav-content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100px;
  position: relative;
}

.nav-content.small {
  padding: 10px;
}

.nav-content.small .nav-logo {
  transform: scale(0.8);
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.nav-list li:nth-child(4) a {
  padding-right: 0;
}

.nav-list li {
  margin: 0;
}

.nav-list a {
  text-decoration: none;
  color: #040D47;
  padding: 15px;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.62px;
}

.nav-list a:hover {
  border-bottom: 1px solid #F4AF1B;
}

.nav-list a.active {
  border-bottom: 1px solid #F4AF1B;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
}

.nav-logo {
  width: 100%;
  transition: transform 0.2s ease;
  transform-origin: center; /* Centre le logo */
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}

/* Début svg animé solutions à ne pas toucher  */

@keyframes draw {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.solutions{
  width: 77%;
  margin-top: -35%;
}

.path {
  stroke: #040D47;
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s forwards, fadeInFillDark 2s ease 0.3s forwards;
}

.path:nth-child(1) {
  animation-delay: 1s;
}
.path:nth-child(2) {
  animation-delay: 1.2s;
}
.path:nth-child(3) {
  animation-delay: 1.4s;
}
.path:nth-child(4) {
  animation-delay: 1.6s;
}

.path:nth-child(5) {
  animation-delay: 1.8s;
}
.path:nth-child(6) {
  animation-delay: 2s;
}
.path:nth-child(7) {
  animation-delay: 2.2s;
}
.path:nth-child(8) {
  animation-delay: 2.4s;
}

.path:nth-child(9) {
  animation-delay: 2.6s;
}

/* Fin partie solutions à ne pas toucher  */


/* Animation du logo principal */

.initiale-w{
  animation: slideFromLeft 1s ease-out forwards;
}

.initiale-f{
  animation: slideFromRight 1s ease-out forwards;
}

.droite-eclair {
  stroke: #F4AF1B;
  fill: lightgray;
  fill-opacity: 0.5;
  stroke-width: 2;
  stroke-dasharray: 1000;
  animation: drawLight 0.5s ease forwards, fadeInFill 1s ease 0.5s forwards;
}

.gauche-eclair {
  stroke: #F4AF1B;
  fill: lightgray;
  fill-opacity: 0.5;
  stroke-width: 2;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: drawLight 0.5s ease forwards, fadeInFill 1s ease 0.5s forwards;
}

@keyframes drawLight {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeInFill {
  from {
    fill: lightgray;
    fill-opacity: 0.5;
  }
  to {
    fill: #F4AF1B;
    fill-opacity: 1;
  }
}

@keyframes fadeInFillDark {
  from {
    fill-opacity: 0;
  }
  to {
    fill: #040D47;
    fill-opacity: 1;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%); /* Commence à gauche, hors de l'écran */
  }
  to {
    transform: translateX(0); /* Termine à la position initiale */
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%); /* Commence à gauche, hors de l'écran */
  }
  to {
    transform: translateX(0); /* Termine à la position initiale */
  }
}

.electric {
  fill: lightgray;
  fill-opacity: 0.5;
  animation: fadeInFill 1.8s ease 0.5s forwards;
}

.electric:nth-child(1) {
  animation-delay: 1s;
}
.electric:nth-child(2) {
  animation-delay: 1.2s;
}
.electric:nth-child(3) {
  animation-delay: 1.4s;
}
.electric:nth-child(4) {
  animation-delay: 1.6s;
}

.electric:nth-child(5) {
  animation-delay: 1.8s;
}
.electric:nth-child(6) {
  animation-delay: 2s;
}
.electric:nth-child(7) {
  animation-delay: 2.2s;
}
.electric:nth-child(8) {
  animation-delay: 2.4s;
}


/* R E S P O N S I V E */

@media (max-width: 650px) {
  .top-block {
    display: none;
  }

  .nav-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-content.small {
    width: 90%;
  }

  .menu-toggle {
    display: block;
    color: #040D47;
  }

  .nav-list {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 60px;
    right: 0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    z-index: 999;
    border-radius: 8px;
  }

  .nav-list.open {
    display: flex;
  }

  .nav-list li {
    width: 100%;
    text-align: left;
  }

  .nav-list a {
    padding: 15px 20px;
    width: 100%;
    display: block;
  }

  .nav-logo {
    width: 150px;
    height: 150px;
  }

  .nav-list a.active {
    border-bottom: 2px solid #F4AF1B;
    width: 90%;
  }

  .solutions{
    width: 70%;
    margin-top: -39%;
  }
  
}
