@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.global-name {
    font-family: 'Montserrat', sans-serif;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #040D47;
}

.global-title-container{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.global-title {
    font-family: 'Montserrat', sans-serif;
    padding-top: 50px;
}

.global-title-description {
    text-align: justify; ;
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto; /* Centrer horizontalement */
    font-family: 'Montserrat', sans-serif;
    margin-top: 100px;
    margin-bottom: 100px;
}

.home-container h1 {
    margin-bottom: 30px;
    letter-spacing: 3px;
    font-weight: 300;
}

.items-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    min-height: 500px;
}

.item {
    display: flex;
    flex-direction: column;
    background-color: #EAE9E4;
    border-radius: 5px;
    text-align: center;
    flex: 1;
    margin: 0 10px; /* Espacement entre les items */
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
}

.item-image-container{
    width: 100%;
    height: 200px;
    min-height: 200px;
}

.item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-title {
    font-size: 1.5em;
    font-weight: 300;
    color: #040D47;
}

.item-description {
    color : #040D47; 
}

.item:first-child {
    margin-left: 0; /* Supprimer la marge gauche du premier item */
}

.item:last-child {
    margin-right: 0; /* Supprimer la marge droite du dernier item */
}

.image-home {
    height: 250px;
    width: 100%;
    object-fit: cover;
}

.item-button {
    display: flex;
    cursor: pointer;
    height: 30px;
    border-radius: 6px;
    background-color: #F4AF1B;
    border-color: transparent;
    color: white;
    padding: 15px;
    font-size: 1em;
    align-items: center;
    margin-bottom: 50px;
}

.item-button:hover {
    background-color:#040D47 ;
    text-decoration: underline;
}

.card {
    display: flex;
    flex-direction: column;
    background-color: #EAE9E4;
    border-radius: 5px;
    text-align: center;
    margin: 0; /* Espacement entre les items */
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    color: #3C8B27;
    overflow: hidden; /* Masque tout contenu qui dépasse du conteneur */
    position: relative;
    margin-top: 50px;
}

.card:nth-child(1){
    margin-right: 10px;
}

.card:nth-child(3){
    margin-left: 10px;
}

.card-image-container {
    width: 100%;
    height: 350px;
    overflow: hidden; /* Masque tout contenu qui dépasse du conteneur */
}

.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease; /* Ajoute une transition pour l'effet de zoom */
}

.card-image:hover {
    transform: scale(1.1); /* Applique un zoom de 10% au survol */
}

.card-content {
    padding: 15px;
}

.card-title {
    font-size: 1.5em;
    font-weight: 300;
}

.card-subtitle {
    color: black;
}

.card:hover {
    color: black;
    cursor: pointer;
}

@media (max-width: 850px) {
    .items-container{
        min-height: 320px;
        height: 320px;
    }

    .item-title {
        font-size: 0.8em;
        font-weight: 300;
        padding: 10px;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .item p{
        font-size: 0.8em;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .item-button{
        font-size: 0.8em;
        margin-bottom: 0;
    }

    .card-image-container {
        min-height: 150px;
    }


}

@media (max-width: 500px) {
    .items-container{
        flex-direction: column;
        margin-bottom: 100px;
    }

    .item{
        margin-bottom: 30px;
    }

    .project-items-container{
        flex-direction: column;
    }

    .card-content{
        width: 100%;
    }

    .project-card-image-container{
        width: 100% !important;
    }

    .project-card{
        padding-bottom: 0 !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .project-container{
        flex-direction: column;
    }

    .project-left-bloc{
        width: 100% !important;
    }

    .project-right-bloc{
        width: 100% !important;
        height: 50vw !important;
        padding: 0 !important;
    }
}


.project-items-container {
    display: flex;
    width: 80%;
    min-height: 300px;
    overflow: hidden; /* Masque les cartes qui dépassent du conteneur */
    position: relative;
}


.project-card {
    display: flex;
    flex-direction: column;
    background-color: #EAE9E4;
    border-radius: 5px;
    text-align: center;
    margin: 0; /* Espacement entre les items */
    justify-content: flex-start;
    align-items: center;
    color: #3C8B27;
    overflow: hidden; /* Masque tout contenu qui dépasse du conteneur */
    position: relative;
    margin-top: 50px;
    padding-bottom: 20px;
    opacity: 0;
    transform: translateX(100%); /* Commence hors de l'écran à droite */
    animation: slideIn 1s forwards; /* Animation d'entrée */
    cursor: pointer;
}



.project-card:nth-child(1){
    margin-right: 20px;
}

.project-card:nth-child(2){
    animation-delay: 0.2s;
}

.project-card:nth-child(3){
    margin-left: 20px;
    animation-delay: 0.4s;
}

.project-card-image-container {
    width: 300px;
    height: 200px;  
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden; /* Masque tout contenu qui dépasse du conteneur */
    position: relative;
}

.project-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease; /* Ajoute une transition pour l'effet de zoom */
    border-radius: 5px;
}

/* Animation de défilement des cartes */
@keyframes slideIn {
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@media (max-width: 850px) {
    .project-card-image-container{
        width: 150px;
        height: 150px;
    }

    .card-title{
        font-size: 1em;
    }

    .card-subtitle{
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .card{
        min-height: 300px;
    }
}


.project-container {
    display: flex;
    width: 80%;
    height: 42vw;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 100px;
  }
  
  .project-left-bloc {
    display: flex;
    width: 35%;
    height: 40vw;
    flex-direction: column;
    padding: 20px;
  }

  .project-left-bloc h3{
    margin-top: 0;
  }
  
  .project-right-bloc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65%;
    height: 40vw;
    padding: 20px;
    padding-right: 0;
  }
  
  .carousel-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .carousel-arrow {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .carousel-arrow.left {
    left: 10px;
  }
  
  .carousel-arrow.right {
    right: 10px;
  }

  .no-margin-bottom {
    margin-bottom: 0px;
  }